<template>
    <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">

        <h3 class="form-subtitle mb__12">{{ $t('Unit.sensors.Inputs') }}</h3>
        <div class="card card_type_match-params mb__12">
            <div class="horizontal-line pfull__12">
                <!-- v-for="(unit_input, i) in unit.inputs" :key="'unit.inputs-'+i" -->
                <FieldGroup_kendoui_combobox
                        :field-group_class="'mr__8'"
                        :id="'input0name'"
                        :label="$t('Unit.sensors.Parameter')"
                        :items="input_types"
                        v-model="unit.inputs[0]['name']"
                />
                <FieldGroup_kwrapper_dropdownlist
                        :id="'input0param'"
                        :label="$t('Unit.sensors.Input')"
                        :items="input_params"
                        :error="unit_inputs_errors[0]['param']"
                        v-model="unit.inputs[0]['param']"
                />
            </div>
            <CheckboxControl
                :control-class="'mr__8'"
                :id="'input0notif'"
                :label="$t('Unit.sensors.Notification')"
                v-model="unit.inputs[0]['notif']"
            />
        </div>
        <div class="card card_type_match-params mb__32">
            <div class="horizontal-line pfull__12">
                <!-- v-for="(unit_input, i) in unit.inputs" :key="'unit.inputs-'+i" -->
                <FieldGroup_kendoui_combobox
                    :field-group_class="'mr__8'"
                    :id="'input1name'"
                    :label="$t('Unit.sensors.Parameter')"
                    :items="input_types"
                    v-model="unit.inputs[1]['name']"
                />
                <FieldGroup_kwrapper_dropdownlist
                    :id="'input1param'"
                    :label="$t('Unit.sensors.Input')"
                    :items="input_params"
                    :error="unit_inputs_errors[1]['param']"
                    v-model="unit.inputs[1]['param']"
                />
            </div>
            <CheckboxControl
                :control-class="'mr__8'"
                :id="'input1notif'"
                :label="$t('Unit.sensors.Notification')"
                v-model="unit.inputs[1]['notif']"
            />
        </div>

        <h3 class="form-subtitle mb__12">{{ $t('Unit.sensors.Outputs') }}</h3>
        <div class="card card_type_match-params mb__32">
            <div class="horizontal-line pfull__12">
                <!-- v-for="(unit_output, i) in unit.outputs" :key="'unit.outputs-'+i" -->
                <FieldGroup_kendoui_combobox
                        :field-group_class="'mr__8'"
                        :id="'output0name'"
                        :label="$t('Unit.sensors.Parameter')"
                        :items="output_types"
                        v-model="unit.outputs[0]['name']"
                />
                <FieldGroup_kwrapper_dropdownlist
                        :id="'output0param'"
                        :label="$t('Unit.sensors.Output')"
                        :items="output_params"
                        :error="unit_outputs_errors[0]['param']"
                        v-model="unit.outputs[0]['param']"
                />
            </div>
            <div class="horizontal-line pfull__12">
                <!-- v-for="(unit_output, i) in unit.outputs" :key="'unit.outputs-'+i" -->
                <FieldGroup_kendoui_combobox
                    :field-group_class="'mr__8'"
                    :id="'output1name'"
                    :label="$t('Unit.sensors.Parameter')"
                    :items="output_types"
                    v-model="unit.outputs[1]['name']"
                />
                <FieldGroup_kwrapper_dropdownlist
                    :id="'output1param'"
                    :label="$t('Unit.sensors.Output')"
                    :items="output_params"
                    :error="unit_outputs_errors[1]['param']"
                    v-model="unit.outputs[1]['param']"
                />
            </div>
        </div>

        <h3 class="form-subtitle mb__12">{{ $t('Unit.sensors.Ignition') }}</h3>
        <SegmentControl
                :segment-control-class="'mb__12'"
                :items="{'': this.$t('ignition.type.None'), 'virtual': this.$t('ignition.type.Virtual'),'physical': this.$t('ignition.type.Physical')}"
                :error="unit_ignition_errors['type']"
                v-model="unit_ignition_type"
        />

        <FieldGroup_input
                :field-group_class="'is_helper mb__12'"
                :id="'voltage_threshold'"
                :label="$t('ignition.text.Voltage threshold')"
                :info="$t('ignition.text.If Voltage value more that threshold value → Ignition On, otherwise Off')"
                :error="unit_ignition_errors['voltage']"
                v-model="unit_ignition.voltage"
                v-if="unit_ignition.type=='virtual'"
        />

        <FieldGroup_kwrapper_dropdownlist
                :id="'output0'"
                :label="$t('Unit.sensors.Ignition')"
                :items="input_params_ign"
                :error="unit_ignition_errors['param']"
                v-model="unit_ignition.param"
                v-if="unit_ignition.type=='physical'"
        />

    </section>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "UnitEdit_Sensors",
    props: [
        'value',
        'unitId',
        'errors',
    ],
    components: {},
    data() {
        return {
            checkboxValue: false,
        }
    },
    computed: {
        ...mapGetters([
            "unitsByIds",
        ]),
        _errors(){//hack on update
            return Object.keys(this.errors)
        },
        input_types() {
            return [
                this.$t('input.types.SOS'),
                this.$t('input.types.Door'),
                this.$t('input.types.Hood')
            ]
        },
        input_params() {
            return [
                {id: 'in1', name: this.$t('input.params.name', {n: 1})},
                {id: 'in2', name: this.$t('input.params.name', {n: 2})},
                {id: 'in3', name: this.$t('input.params.name', {n: 3})},
                {id: 'in4', name: this.$t('input.params.name', {n: 4})},
            ]
        },
        output_types() {
            return [
                this.$t('output.types.Buzzer'),
                this.$t('output.types.Engine'),
                this.$t('output.types.LED')
            ]
        },
        output_params() {
            return [
                {id: 'out1', name: this.$t('output.params.name', {n: 1})},
                {id: 'out2', name: this.$t('output.params.name', {n: 2})},
                {id: 'out3', name: this.$t('output.params.name', {n: 3})},
                {id: 'out4', name: this.$t('output.params.name', {n: 4})},
            ]
        },

        input_params_ign(){
            let params = this.input_params
            params.push({id:'ign',name: this.$t('input.params.HW Ignition')})
            return params
        },

        unit(){
            return this.value
        },
        unit_ignition(){
            return this.unit.ignition
        },
        unit_ignition_type:{
            get(){ return this.unit.ignition.type },
            set(type){ this.unit.ignition.type = type },
        },
        unit_ignition_errors(){
            return (this.errors.ignition || {})
        },
        unit_inputs_errors(){
            return (this.errors.inputs || [{},{}])
        },
        unit_outputs_errors(){
            return (this.errors.outputs || [{},{}])
        },
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        fix(){
            let size = 2

            if(!this.unit.inputs) this.unit.inputs = []
            while(this.unit.inputs.length<size){
                this.unit.inputs.push({name:'',param:'',notif:''})
            }
            if(!this.unit.outputs) this.unit.outputs = []
            while(this.unit.outputs.length<size){
                this.unit.outputs.push({name:'',param:''})
            }

            if(!this.unit.ignition) this.unit.ignition = {}
        }
    },
    created() {
        // console.log('UnitEdit_Options created', this.unitId, this.unit)
    },
    mounted() {
        // console.log('UnitEdit_Options mounted', this.unitId, this.unit)
        //this.fix()
    },
    updated() {
        // console.log('UnitEdit_Options updated', this.unitId, this.unit)
        //this.fix()
    },
}
</script>

<style scoped>

</style>